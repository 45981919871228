import Layout from "../components/Layout";
import Snackbar from "../components/Snackbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
function AppRoute({ ...params }) {
  return (
    <Layout>
      <Snackbar />
      <Route {...params} />
    </Layout>
  );
}
export default AppRoute;
