import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoImg from "../components/unstake_components/InfoImg.js";

import CustomModal from "../components/unstake_components/CustomModal.js";

import { Box } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { unStakeAmount } from "../contract/functions/functions";
import { setLoading, showSnackbar, getChainData } from "../redux";

import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";

import { APP_TOKEN_NAME, APP_UNSTAKING_MESSAGE } from "../utils/constants.js";
import { InfoPng } from "../assets/index.js";
import AmountInput from "../components/stake_components/AmountInput.js";
import BlockBtn from "../components/stake_components/BlockBtn.js";
import * as web3Actions from "../redux/actions/web3Actions";

const Unstake = () => {
  const classes = useStyles();
  const context = useWeb3React();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.ui);

  const networkSet = useSelector((state) => state.web3.networkSet);
  const minStakingAmount = useSelector(
    (state) => state.web3.data.minStakingAmount
  );
  const minStakingPeriod = useSelector(
    (state) => state.web3.data.minStakingPeriod
  );

  const stakingPeriodValue = useSelector(
    (state) => state.web3.data.stakingPeriodValue
  );

  const stakingPeriod = useSelector((state) => state.web3.data.stakingPeriod);

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);
  const rewardFee = useSelector((state) => state.web3.data.rewardFee);

  const { approved } = useSelector((state) => state.web3.data);
  const userData = useSelector((state) => state.web3.data);

  const [userBalance, setUserBalance] = useState(0);
  const [userInput, setUserInput] = useState();
  const [inputErrText, setInputErrText] = useState("");
  const [showInputErr, setShowInputErr] = useState(false);

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalLoadText, setModalLoadText] = useState("");

  const web3Context = useWeb3React();

  useEffect(() => {
    if (context.account && context.active && networkSet) {
      getChainDataFunc();
    }
  }, [context, networkSet, currentNetwork]);

  useEffect(() => {
    if (context?.account) {
      setUserInput("");
    }
  }, [currentNetwork, context]);

  const changeLoadingStatus = (flag) => {
    dispatch(setLoading(flag));
  };

  const getChainDataFunc = () => {
    dispatch(getChainData(context.account, null, context.chainId));
  };

  const onUnStakeCallback = () => {
    dispatch(getChainData(context.account, currentNetwork, context.chainId));

    let counter = 0;

    let interval = setInterval(() => {
      console.log("Counter => ", counter);
      if (counter < 3) {
        dispatch(web3Actions.getTotalNetworkStakes(web3Context?.chainId));
        dispatch(web3Actions.getTransactionsHistory(web3Context?.chainId));
        counter = counter + 1;
      } else {
        clearInterval(interval);
      }
    }, 2000);
  };

  const onUnStake = async (value, totalStaked) => {
    value = Number(value);
    totalStaked = Number(totalStaked);
    if (value <= totalStaked) {
      let userStakeAmount = await unStakeAmount(
        value,
        context.account,

        changeLoadingStatus,
        setOpen,
        showSnackbarFunc,
        setUserInput,
        onUnStakeCallback
      );
    } else if (value > totalStaked) {
      console.log(typeof value);
      console.log(typeof totalStaked);
    }
  };

  const handleClickOpen = () => {
    setModalText(
      `Unstaking will automatically convert your d${APP_TOKEN_NAME} rewards into ${APP_TOKEN_NAME}`
    );
    setModalLoadText(`Unstaking ${userInput} ${APP_TOKEN_NAME}`);
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
  };

  let modalFunc = () => {
    onUnStake(userInput, userData.dafiStaked);
  };
  const showSnackbarFunc = (data, severity) => {
    dispatch(showSnackbar({ message: data, severity }));
  };

  function hasDecimal(num) {
    return num.includes(".") && /\d/.test(num);
  }

  const onChangeInput = (input) => {
    if (input.includes("+") || input.includes("-")) {
      return;
    }
    if (hasDecimal(input)) {
      let split = input.split(".");
      if (split[0].length > 18 || split[1].length > 6) {
        return;
      }
    } else if (input.length > 18) {
      return;
    }
    if (input.includes(" ")) {
      setUserInput("");
      return;
    }

    if (Number.isNaN(Number(input))) {
      if (Number(input)) {
        setInputErrText(
          `The amount entered is greater than your total number of staked ${APP_TOKEN_NAME}`
        );
        setShowInputErr(true);
      } else if (Number(input) === 0) {
        setInputErrText("Unstake amount must be greater than zero");
        setShowInputErr(true);
      }
      if (!input.split(".")[0]) {
        setUserInput("0" + input);
      }
      return;
    }
    setShowInputErr(false);
    setInputErrText("");
    if (Number(input) <= userData.dafiStaked && Number(input) !== 0) {
      setInputErrText("");
      setShowInputErr(false);
      setUserInput(input);
    } else {
      if (Number(input)) {
        setUserInput(input);
        setInputErrText(
          `The amount entered is greater than your total number of staked ${APP_TOKEN_NAME}`
        );
      } else if (Number(input) === 0) {
        setUserInput(input);
        setInputErrText("Unstake amount must be greater than zero");
      }
      setShowInputErr(true);
    }
  };

  const onMaxClick = () => {
    setShowInputErr(false);
    setInputErrText("");
    setUserInput(Number(userData.dafiStaked));
  };

  return (
    <div className={classes.container}>
      <CustomModal
        open={open}
        onClose={handleClose}
        text={modalText}
        modalFunc={modalFunc}
        loadText={modalLoadText}
        approved={approved}
        stakingPeriod={stakingPeriod}
        stakingPeriodValue={stakingPeriodValue}
        minStakingPeriod={minStakingPeriod}
      />
      <Box>
        <div className={classes.stakeContainer}>
          <Card elevation={1} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.title}>Unstake</Typography>
              <div className={classes.horizontalLine}></div>
              {
                // currentNetwork === 3 ||
                //   currentNetwork === 2 ||
                //   currentNetwork === 4 ||
                currentNetwork === 5 && (
                  <div className={classes.withdrawInfo}>
                    <img src={InfoPng} className={classes.infoIcon} />A{" "}
                    {rewardFee}% toll is deducted from your QUIDD rewards when
                    exiting a pool or claiming rewards. These tokens are then
                    distributed amongst Super Stakers, boosting APY.
                  </div>
                )
              }
              <InfoImg
                stakedAmount={userData.dafiStaked}
                rewards={userData.rewards}
              />

              <AmountInput
                userBalance={userBalance}
                minStakingAmount={minStakingAmount}
                minStakingPeriod={minStakingPeriod}
                userInput={userInput}
                setUserInput={setUserInput}
                stakedAmount={userData.dafiStaked}
                inputErrText={inputErrText}
                showInputErr={showInputErr}
                setInputErrText={setInputErrText}
                setShowInputErr={setShowInputErr}
                rewards={userData.rewards}
                onChangeInput={onChangeInput}
                disabled={!web3Context.active && !web3Context.account}
                onMaxClick={onMaxClick}
                maxDisabled={userData?.dafiStaked <= 0}
              />

              <div className={classes.marginBtm}></div>
              <BlockBtn
                label={!loading ? "Unstake" : "Transaction Pending"}
                handleClick={handleClickOpen}
                disabled={
                  showInputErr ||
                  loading ||
                  (!userInput && currentNetwork !== 1) ||
                  !userInput ||
                  userInput <= 0 ||
                  !context.active
                }
              />
            </CardContent>
          </Card>
        </div>
      </Box>
    </div>
  );
};

export default Unstake;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,

    border: "2px solid rgba(98, 38, 207, 0.2)",
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "none",
    width: "473px",
    position: "relative",
    "@media(max-width:550px)": {
      width: "90%",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    border: 0,
  },
  container: {
    height: "100%",
    // width: "100%",
    "@media(max-width:550px)": {
      marginLeft: "-16px",
    },
    "@media(max-width:320px)": {
      marginLeft: "0px",
    },
  },
  horizontalLine: {
    borderBottom: "1px solid #ffffff1A",
    width: "120%",
    position: "absolute",
    top: "54px",
  },
  infoIcon: {
    width: "20px",
    marginRight: "10px",
    "@media(max-width:550px)": {
      marginBottom: "6px",
    },
  },

  marginBtm: {
    marginBottom: "13px",
  },

  title: {
    fontWeight: 600,
    fontSize: "18px",
    color: "black",
    marginBottom: "38px",
  },
  verticalLine: {
    borderLeft: "1px solid #ffffff1A",
    height: "52px",
    marginRight: "17px",
  },
  stakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  withdrawInfo: {
    backgroundColor: theme.palette.cardBgColor,
    border: theme.palette.border,
    color: "black",
    padding: "14px",
    width: "100%",
    borderRadius: "10px",

    marginBottom: "20px",
    marginTop: "-10px",
    fontWeight: 400,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "@media(max-width:550px)": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
  },
}));
