import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: "2px solid rgba(255, 255, 255, 0.02)",
    marginRight: "16px",
  },

  type: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "black",

    fontWeight: 400,
    textAlign: "center",
  },

  value: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "black",

    fontWeight: 400,
    textAlign: "center",
  },

  circle: {
    height: 12,
    width: 12,
    borderRadius: 100,
    marginRight: 5,
    background: "#1CDDC2",
  },
}));

const HolderCard = ({ type, value }) => {
  const classes = useStyles(type);

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        alignItems="center"
        marginBottom="5px"
        justifyContent="center"
      >
        <Box
          className={classes.circle}
          style={{
            backgroundColor:
              type === "ERC20"
                ? "#aa5e81"
                : type === "BEP20"
                ? "#b4b3b5"
                : "#8247E5",
          }}
        ></Box>
        <Typography className={classes.value}>{value}</Typography>
      </Box>
      <Typography className={classes.type}>{`${type} holders`}</Typography>
    </Box>
  );
};

export default HolderCard;
