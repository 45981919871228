import {
  Box,
  Grid,
  useMediaQuery,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Mask } from "../assets";
import React, { useEffect, useState } from "react";
import { BinanceIcon, EthereumIcon, Matic2 } from "../assets";
import SuperPoolCard from "../components/Cards/SuperPoolCard";

import PoolGraphCard from "../components/Cards/PoolGraphCard";
import { useDispatch, useSelector } from "react-redux";

import * as web3Actions from "../redux";

import { useWeb3React } from "@web3-react/core";
import { APP_SUPERPOOL_PAGE_TITLE } from "../utils/constants";
import MarketGraphCard from "../components/MarketComponents/MarketGraphCard";
import TokenStatsCard from "../components/MarketComponents/TokenStatsCard";
import moment from "moment";
import TwitterFeedCard from "../components/MarketComponents/TwitterFeedCard";
import APYCard from "../components/MarketComponents/APYCard";
import TVLCard from "../components/MarketComponents/TVLCard";

const SuperPool = () => {
  const networkSet = useSelector((state) => state.web3.networkSet);

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);

  const [cardsData, setCardsData] = useState([
    {
      title: "Binance",
      icon: BinanceIcon,
      dafiLocked: "0",
      APY: "0",
      potentialAPY: "0",
      themeColor: "#a37ce6",
      blur: false,
    },
  ]);

  const web3Context = useWeb3React();

  const dispatch = useDispatch();

  useEffect(() => {
    document.querySelector("title").textContent = APP_SUPERPOOL_PAGE_TITLE;
  }, []);

  useEffect(() => {
    dispatch(
      web3Actions.getCoinMarketChart(moment().subtract(4, "hours").unix())
    );
    dispatch(web3Actions.getCoinCurrentPrice());
    dispatch(web3Actions.getCoinOtherData());

    dispatch(web3Actions.getTwitterTweets());

    if (networkSet) dispatch(web3Actions.getCoinDemandFactor(currentNetwork));
  }, [networkSet]);

  const OverallTotalStakes = useSelector(
    (state) => state.web3.data.OverallTotalStakes
  );

  const overallAPY = useSelector((state) => state.web3.data.overallAPY);
  const overallPotentialAPY = useSelector(
    (state) => state.web3.data.overallPotentialAPY
  );

  useEffect(() => {
    dispatch(web3Actions.getTotalNetworkStakes(web3Context?.chainId));
    dispatch(
      web3Actions.getOverallAPY(
        web3Context?.chainId,
        web3Context.account,
        currentNetwork
      )
    );
    dispatch(
      web3Actions.getOverAllPotentialAPY(
        web3Context?.chainId,
        web3Context.account,
        currentNetwork
      )
    );
  }, [web3Context, currentNetwork]);
  console.log("overallAPY", overallAPY);
  useEffect(() => {
    (async () => {
      if (currentNetwork) {
        dispatch(web3Actions.setAppLoading(true));

        // if (currentNetwork === 3) {
        //   setCardsData([
        //     {
        //       title: "Polygon",
        //       icon: Matic2,
        //       dafiLocked: OverallTotalStakes?.maticV2
        //         ? OverallTotalStakes?.maticV2
        //         : 0,
        //       APY: overallAPY?.maticV2APY
        //         ? overallAPY.maticV2APY.toFixed(2)
        //         : 0,
        //       potentialAPY: overallPotentialAPY?.maticV2PotentialAPY
        //         ? overallPotentialAPY?.maticV2PotentialAPY
        //         : 0,
        //       themeColor: "#a37ce6",
        //       blur: false,
        //     },
        //   ]);
        // } else if (currentNetwork === 2) {
        //   setCardsData([
        //     {
        //       title: "Ethereum",
        //       icon: EthereumIcon,
        //       dafiLocked: OverallTotalStakes?.ethereumV2
        //         ? OverallTotalStakes?.ethereumV2
        //         : 0,
        //       APY: overallAPY?.ethereumV2APY
        //         ? overallAPY.ethereumV2APY.toFixed(2)
        //         : 0,
        //       potentialAPY: overallPotentialAPY?.ethereumV2PotentialAPY
        //         ? overallPotentialAPY?.ethereumV2PotentialAPY
        //         : 0,
        //       themeColor: "#BBD0FF",
        //       blur: false,
        //     },
        //   ]);
        // }
        if (currentNetwork === 5) {
          setCardsData([
            {
              title: "Binance",
              icon: BinanceIcon,
              dafiLocked: OverallTotalStakes?.bscV2
                ? OverallTotalStakes?.bscV2
                : 0,
              APY: overallAPY?.bscV2APY ? overallAPY.bscV2APY.toFixed(2) : 0,
              potentialAPY: overallPotentialAPY?.bscV2PotentialAPY
                ? overallPotentialAPY?.bscV2PotentialAPY
                : 0,
              themeColor: "#F3BA2F",
              blur: false,
            },
          ]);
        }
        dispatch(web3Actions.setAppLoading(false));
      }
    })();
  }, [
    networkSet,
    OverallTotalStakes,
    overallAPY,
    overallPotentialAPY,
    currentNetwork,
  ]);

  const [activeCard, setActiveCard] = useState(0);

  const matches = useMediaQuery("(max-width:600px)");

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {" "}
      <img src={Mask} alt="app mask" className="maskImageSuperPool" />
      <Box>
        <Box>
          <Grid container className={classes.cardsContainer}>
            <Grid item xs={12} md={7} lg={8}>
              <MarketGraphCard />
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <TokenStatsCard />
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid item xs={12} md={7} lg={8}>
            <APYCard
              active={true}
              onClick={() => {}}
              card={cardsData[activeCard]}
            />
          </Grid>

          <Grid item xs={12} md={5} lg={4}>
            <TVLCard card={cardsData[activeCard]} />
          </Grid>
        </Grid>

        <PoolGraphCard card={cardsData[activeCard]} />

        <Grid item xs={12} md={12} lg={12}>
          <Box display="flex" flexDirection="column" height="100%">
            <TwitterFeedCard />
          </Box>
        </Grid>
      </Box>
    </div>
  );
};

export default SuperPool;
const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    marginBottom: 16,

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  container: {
    position: "relative",
    height: "100%",
  },

  exchangeCardTablet: {
    display: "none",

    [theme.breakpoints.down("1100")]: {
      display: "flex",
    },
  },
  exchangeCardNonTablet: {
    [theme.breakpoints.down("1100")]: {
      display: "none",
    },
  },
  walletHolderFeedContainer: {
    display: "none",
    [theme.breakpoints.down("650")]: {
      display: "flex",
    },
  },

  walletHolderFeedContainerTablet: {
    display: "none",
    [theme.breakpoints.up("650")]: {
      display: "flex",
    },
  },
}));
