// export const DAFI_ADDRESS_POLYGON_MUMBAI =
//   "0xe61B223100676D6Ffa588C7c6720348DCAD3131F";

// export const NETWORK_DEMAND_POLYGON_MUMBAI =
//   "0xF487571686c6Dc5c905409572D28f58DEC8aB4DB";

// export const STAKING_DATABASE_POLYGON_MUMBAI =
//   "0xd2A871713509843c0dcbA26eF7885986EDD767df";

// export const STAKING_MANAGER_POLYGON_MUMBAI =
// "0x49122e3E5C30577799aaA480D097Cb2940a8faF3";

// End

// App Mainnet
// export const TOKEN_ADDRESS_POLYGON_MAINNET =
//   "0xd1790c5435b9fb7c9444c749cefe53d40d751eac";

// export const NETWORK_DEMAND_POLYGON_MAINNET =
//   "0x47DF72E00AB929cc67Be8bBA4702655a025858b4";

// export const STAKING_DATABASE_POLYGON_MAINNET =
//   "0xFfd1cD8f989F7563363AeE8D32902c16c7DDa01D";

// export const STAKING_MANAGER_POLYGON_MAINNET =
//   "0xc5884324eAF7fD4a13EA9550B15F006374862969";
//

//App Mainnet 2
// export const TOKEN_ADDRESS_ETHEREUM_MAINNET =
//   "0x71dc40668682a124231301414167e4cf7f55383c";

// export const NETWORK_DEMAND_ETHEREUM_MAINNET =
//   "0xe65e298bfc0b82d5755400d2d4320f96403233cd";

// export const STAKING_DATABASE_ETHEREUM_MAINNET =
//   "0xd2e352735540e3af4B1b4AED3F00df45b62f0Eb8";

// export const STAKING_MANAGER_ETHEREUM_MAINNET =
//   "0x2E57a26Ae716Adaf3930fD63859431eEEe405dbE";

//App Mainnet 3
export const TOKEN_ADDRESS_BSC_MAINNET =
  "0x7961ade0a767c0e5b67dd1a1f78ba44f727642ed";

export const NETWORK_DEMAND_BSC_MAINNET =
  "0x5fA6787570f0d55759Cf979995d5dbab7fDaf8dD";

export const STAKING_DATABASE_BSC_MAINNET =
  "0xE31015F60fD4797Cd221999e484763A238dCbCe2";

export const STAKING_MANAGER_BSC_MAINNET =
  "0x0d4Aaf8b27DEaE43141627610B8C028003847549";

export const API_BASE_URL = "https://quidd-server.superstaking.io";
// export const API_BASE_URL = "http://localhost:3000";
// export const API_BASE_URL = "http://18.212.221.233:3000/";

export const API_DEV_BASE_URL = "http://18.212.221.233:3000/";
export const GRAPH_BASE_URL =
  "https://api.thegraph.com/subgraphs/name/mauwia/quid";
