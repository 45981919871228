import { makeStyles, Box, Typography } from "@material-ui/core";
import React from "react";
import HolderCard from "./HolderCard";
import PieChart from "./PieChart";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    // minHeight: 470,
    // minWidth: 310,
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",
    border: "2px solid rgba(98, 38, 207, 0.2)",
    borderRadius: "25px",
    padding: 24,
    paddingBottom: 0,
    marginRight: 16,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("1100")]: {
      padding: 16,
    },

    [theme.breakpoints.down("650")]: {
      padding: 16,

      marginRight: 0,
      marginBottom: 16,
    },
  },

  heading: {
    fontSize: "18px",
    lineHeight: "20px",
    color: "black",
    fontWeight: 600,
    // marginBottom: 16,
  },

  chartAndValuesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    flex: 1,
  },
}));

const WalletHoldersCard = () => {
  const classes = useStyles();

  const walletHolders = useSelector((state) => state.web3.data.walletHolders);

  console.log("walletHolders => ", walletHolders);

  return (
    <Box className={classes.container}>
      <Typography className={classes.heading}>DAFI Users</Typography>

      <Box className={classes.chartAndValuesContainer}>
        <Box>
          <PieChart
            // total={
            //   Number(walletHolders?.ethUsers - 4) +
            //   Number(walletHolders?.bnbUsers - 2) +
            //   Number(walletHolders?.polygonUsers)
            // }

            total={
              Number(walletHolders?.ethUsers - 4) +
              Number(walletHolders?.polygonUsers)
            }
            walletHolders={walletHolders}
          />
        </Box>

        <Box display="flex" justifyContent="center">
          <HolderCard value={walletHolders?.ethUsers - 4} type="ERC20" />
          {/* <HolderCard value={walletHolders?.bnbUsers - 2} type="BEP20" /> */}

          <HolderCard value={walletHolders?.polygonUsers} type="Polygon" />
          {/* <HolderCard
            value={
              walletHolders?.polygonUsers ? walletHolders?.polygonUsers : 0
            }
            type="Polygon"
          /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default WalletHoldersCard;
