import { makeStyles, Typography, Box, Button } from "@material-ui/core";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError,
} from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import React, { useCallback } from "react";
import { useSnackbar } from "../../hooks/uiHooks";
import { useWeb3 } from "../../hooks/useWeb3";
import { injected } from "../../utils/web3Connectors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { walletconnect } from "../../utils/web3ConnectFunctions";
import { MetaMaskIcon, TrustWalletIcon } from "../../assets";
import { BrowserView, MobileView } from "react-device-detect";
import { setActiveTrustWallet } from "../../redux";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 30,
    fontWeight: 700,
    color: "black",
    marginBottom: 24,
  },

  contentContainer: {
    width: 400,
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",
    border: "2px solid rgba(255, 255, 255, 0.05)",
    borderRadius: 15,
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  button: {
    width: "100%",
    marginBottom: 16,
    height: 50,
    display: "flex",

    [theme.breakpoints.down("960")]: {
      minWidth: 272,
    },
  },
}));

const ConnectWalletModal = ({ onSuccess }) => {
  const classes = useStyles();

  const web3context = useWeb3React();

  const { showSnackbarF } = useSnackbar();
  const dispatch = useDispatch();
  // const { web3context } = useWeb3();

  console.log(
    "xxxx => ",
    web3context?.connector instanceof WalletConnectConnector,
    web3context?.connector instanceof InjectedConnector
  );

  const deactivateWallet = async () => {
    await web3context.deactivate();
    console.log(web3context, "TEST", web3context.active);
    if (web3context?.connector instanceof WalletConnectConnector) {
      await web3context.connector.close();
    }

    dispatch(setActiveTrustWallet(false));

    onSuccess();
  };

  const getErrorMessage = (e) => {
    if (e instanceof UnsupportedChainIdError) {
      return "Unsupported Network";
    } else if (e instanceof NoEthereumProviderError) {
      return "No Wallet Found";
    } else if (e instanceof UserRejectedRequestError) {
      return "Wallet Connection Rejected";
    } else if (e.code === -32002) {
      return "Wallet Connection Request Pending";
    } else {
      return "An Error Occurred";
    }
  };

  const activateWallet = useCallback(
    (connector, onClose = () => {}) => {
      if (
        connector instanceof WalletConnectConnector &&
        connector.walletConnectProvider?.wc?.uri
      ) {
        connector.walletConnectProvider = undefined;
      }

      web3context
        .activate(
          connector
            ? connector
            : new InjectedConnector({
                supportedChainIds: [1, 3, 4, 5, 42, 97, 56, 137, 80001],
              }),
          undefined,
          true
        )
        .then((res) => {
          //   setLoadingF({ walletConnection: false });
          //getJWTF(web3context.account, Date.now());

          console.log("activateWallet connector => ", { connector, res });

          onSuccess();
        })
        .catch((e) => {
          const err = getErrorMessage(e);
          showSnackbarF({ message: err, severity: "error" });
          console.error("ERROR activateWallet -> ", e);
          //   setLoadingF({ walletConnection: false });
        });
    },
    [web3context]
  );

  const activateTrustWallet = useCallback(
    (connector, onClose = () => {}) => {
      if (
        connector instanceof WalletConnectConnector &&
        connector.walletConnectProvider?.wc?.uri
      ) {
        connector.walletConnectProvider = undefined;
      }

      web3context
        .activate(
          connector
            ? connector
            : new InjectedConnector({
                supportedChainIds: [1, 3, 4, 5, 42, 97, 56, 137, 80001],
              }),
          undefined,
          true
        )
        .then((res) => {
          //   setLoadingF({ walletConnection: false });
          //getJWTF(web3context.account, Date.now());

          console.log("activateWallet connector => ", { connector, res });
          dispatch(setActiveTrustWallet(true));
          onSuccess();
        })
        .catch((e) => {
          const err = getErrorMessage(e);
          showSnackbarF({ message: err, severity: "error" });
          console.error("ERROR activateWallet -> ", e);
          //   setLoadingF({ walletConnection: false });
        });
    },
    [web3context]
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography className={classes.heading}>Connect Wallet</Typography>
      <Box
        className={classes.contentContainer}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <BrowserView style={{ width: "100%" }}>
          <Button
            variant="contained"
            onClick={() =>
              !web3context.active &&
              !(web3context?.connector instanceof InjectedConnector)
                ? activateWallet(injected)
                : deactivateWallet()
            }
            className={classes.button}
            endIcon={
              web3context.active &&
              web3context?.connector instanceof InjectedConnector && (
                <CheckCircleIcon />
              )
            }
          >
            <img src={MetaMaskIcon} style={{ marginRight: 10 }} />
            <Typography>
              {web3context.active &&
              web3context?.connector instanceof InjectedConnector
                ? "Disconnect MetaMask"
                : "MetaMask"}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              !web3context.active &&
              !(web3context?.connector instanceof WalletConnectConnector)
                ? activateTrustWallet(walletconnect)
                : deactivateWallet();
            }}
            className={classes.button}
            endIcon={
              web3context.active &&
              web3context?.connector instanceof WalletConnectConnector && (
                <CheckCircleIcon />
              )
            }
          >
            <img src={TrustWalletIcon} style={{ marginRight: 10, width:'35px', height:'35px' }} />
            <Typography>
              {web3context.active &&
              web3context?.connector instanceof WalletConnectConnector
                ? "Disconnect WalletConnect"
                : "WalletConnect"}
            </Typography>
          </Button>
        </BrowserView>
        <MobileView style={{ width: "100%" }}>
          <Button
            variant="contained"
            onClick={() => {
              !web3context.active &&
              !(web3context?.connector instanceof WalletConnectConnector)
                ? activateWallet(walletconnect)
                : deactivateWallet();
            }}
            className={classes.button}
            endIcon={
              web3context.active &&
              web3context?.connector instanceof WalletConnectConnector && (
                <CheckCircleIcon />
              )
            }
          >
            <Typography>
              {web3context.active &&
              web3context?.connector instanceof WalletConnectConnector
                ? "Disconnect Wallet"
                : "Connect Wallet"}
            </Typography>
          </Button>
        </MobileView>
      </Box>
    </Box>
  );
};

export default ConnectWalletModal;
