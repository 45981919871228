import { makeStyles, Box, Typography } from "@material-ui/core";
import React from "react";
import { DafiTwitterJpg, TwitterAvatarSvg } from "../../assets/";
import { TWITTER_HANDLE, APP_NAME } from "../../utils/constants";

const TweetCard = ({ text, user, attachment }) => {
  const classes = useStyles();
  console.log("textTwitter", text);
  return (
    <Box className={classes.container}>
      <img
        src={DafiTwitterJpg}
        style={{ marginRight: 12, borderRadius: 100, height: 50 }}
      />

      <Box style={{ width: "100%" }}>
        <Typography className={classes.title}>{APP_NAME}</Typography>

        <Typography className={classes.username}>{TWITTER_HANDLE}</Typography>

        <Typography className={classes.tweet}>{text}</Typography>

        {attachment && attachment?.url && (
          <Box display="flex" justifyContent="center" margin={"0px auto"}>
            <img src={attachment?.url} className={classes.tweetMedia} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TweetCard;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",

    borderBottom: "2px solid rgba(255, 255, 255, 0.03)",
    padding: "16px 0",
  },

  title: {
    fontSize: "20px",
    lineHeight: "19px",
    color: "black",
    fontWeight: 500,
    marginBottom: 6,
  },

  username: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "rgba(98, 38, 207, 0.7)",
    fontWeight: 500,
    marginBottom: 6,
  },
  tweet: {
    whiteSpace: "pre-wrap",
    fontSize: "14px",
    lineHeight: "19px",
    color: "black",
    fontWeight: 500,
    marginBottom: 16,
  },

  tweetMedia: {
    height: 300,
    [theme.breakpoints.down("600")]: {
      width: "100%",
      height: "100%",
    },
  },
}));
