import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";

import CustomCard from "./CustomCard";

import Chart from "react-apexcharts";
import { useEffect } from "react";
import { getSuperPoolGraphData } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";

const PoolGraphCard = ({ card }) => {
  const classes = useStyles();

  const web3context = useWeb3React();

  const [series, setSeries] = useState([
    {
      name: "series1",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      width: 30,
      type: "area",
      zoom: {
        enabled: false,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      toolbar: {
        show: false,
      },
      foreColor: "black",
      fontFamily: "Poppins",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },

    legend: {
      show: false,
    },

    yaxis: {
      show: false,
    },

    // xaxis: {
    //   show: false,
    // },

    grid: {
      show: false,
    },

    noData: {
      text: "No Data Available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "20px",
        fontFamily: undefined,
      },
    },
  });

  const dispatch = useDispatch();

  const superPoolGraphDataMaticV2 = useSelector(
    (state) => state.web3.data.superPoolGraphDataMaticV2
  );

  const superPoolGraphDataEthV2 = useSelector(
    (state) => state.web3.data.superPoolGraphDataEthV2
  );

  const superPoolGraphDataBscV2 = useSelector(
    (state) => state.web3.data.superPoolGraphDataBscV2
  );

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);

  console.log("superPoolGraphDataMaticV2 => ", superPoolGraphDataMaticV2);

  useEffect(() => {
    if (web3context?.chainId)
      dispatch(getSuperPoolGraphData(web3context?.chainId));
  }, [web3context, currentNetwork]);

  useEffect(() => {
    if (superPoolGraphDataMaticV2 && card.title === "Polygon") {
      setOptions({});
      setSeries([
        {
          name: "Total Staked",
          data: superPoolGraphDataMaticV2?.rewardBalanceArray,
        },
      ]);

      setOptions({
        ...options,
        colors: [card.themeColor],
        fill: {
          colors: [card.themeColor],
        },
        xaxis: {
          type: "datetime",
          categories: superPoolGraphDataMaticV2?.timeArray,
          show: superPoolGraphDataMaticV2.timeArray.length > 0,
        },

        yaxis: {
          show: superPoolGraphDataMaticV2.rewardBalanceArray.length > 0,
        },

        grid: {
          show: false,
        },
      });
    } else if (superPoolGraphDataEthV2 && card.title === "Ethereum") {
      setOptions({});
      setSeries([
        {
          name: "Total Staked",
          data: superPoolGraphDataEthV2?.rewardBalanceArray,
        },
      ]);

      setOptions({
        ...options,
        colors: [card.themeColor],
        fill: {
          colors: [card.themeColor],
        },
        xaxis: {
          type: "datetime",
          categories: superPoolGraphDataEthV2?.timeArray,
          show: superPoolGraphDataEthV2.timeArray.length > 0,
        },

        yaxis: {
          show: superPoolGraphDataEthV2.rewardBalanceArray.length > 0,
        },

        grid: {
          show: false,
        },
      });
    } else if (superPoolGraphDataBscV2 && card.title === "Binance") {
      setOptions({});
      setSeries([
        {
          name: "Total Staked",
          data: superPoolGraphDataBscV2?.rewardBalanceArray,
        },
      ]);

      setOptions({
        ...options,
        colors: [card.themeColor],
        fill: {
          colors: [card.themeColor],
        },
        xaxis: {
          type: "datetime",
          categories: superPoolGraphDataBscV2?.timeArray,
          show: superPoolGraphDataBscV2.timeArray.length > 0,
        },

        yaxis: {
          show: superPoolGraphDataBscV2.rewardBalanceArray.length > 0,
        },

        grid: {
          show: false,
        },
      });
    }
  }, [
    card,
    superPoolGraphDataMaticV2,
    superPoolGraphDataEthV2,
    superPoolGraphDataBscV2,
  ]);
  console.log("web3context.active", web3context.active);
  console.log("web3context.account", web3context.account);
  return (
    <Card elevation={1} className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box display="flex" alignItems="center" className={classes.header}>
          <img className={classes.image} src={card.icon} />
          <Typography className={classes.text}>{card.title}</Typography>
        </Box>
        {currentNetwork === 5 ? (
          <Chart options={options} series={series} type="area" height={350} />
        ) : null}
      </CardContent>
    </Card>
  );
};

export default PoolGraphCard;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 25,

    display: "flex",

    flexDirection: "column",
    boxShadow: "none",

    height: 420,
    // marginRight: 16,
    padding: 24,
    marginBottom: 16,
    overflow: "unset",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
      height: 450,
    },
  },

  text: {
    color: "black",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "500",

    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
  },

  value: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "20px",
    color: "black",
    marginBottom: 20,
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    overflow: "unset !important",
  },

  image: {
    alignItems: "center",
    width: 80,
    marginBottom: 24,
    height: 80,
  },

  timeFrameBtn: {
    background: "rgba(0,0,0,0.1)",
    border: "2px solid rgba(98, 38, 207, 0.2)",
    boxShadow: "inset 0px 23.0177px 33.051px -33px rgba(255, 255, 255, 0.5)",
    borderRadius: "10px",
    color: "black",
    marginRight: 12,
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "600",
    height: 32,
    width: 76,
  },

  header: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
      alignItems: "flex-start",
    },
  },

  image: {
    width: 24,

    height: 24,
    marginRight: 10,
  },
}));
