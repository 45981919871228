import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { TrendingDownSvg, TrendingUpSvg } from "../../assets/index";

const TokenStat = ({ label, value, trend }) => {
  const classes = useStyles({ trend });

  return (
    <Box>
      <Box className={classes.container}>
        <Typography className={classes.label}>{label}</Typography>
        <Box display="flex">
          <Typography className={classes.value}>{value}</Typography>
          {trend && (
            <Box display="flex" alignItems="center">
              <Typography
                className={[classes.trend, classes.trendingBox]}
              >{`${trend}%`}</Typography>
              {trend > 0 ? (
                <img src={TrendingUpSvg} className={classes.trendingBox} />
              ) : (
                <img src={TrendingDownSvg} className={classes.trendingBox} />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <div className={classes.horizontalLine}></div>
    </Box>
  );
};

export default TokenStat;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    borderBottom: "2px solid rgba(255, 255, 255, 0.02)",
    padding: "20px 24px",
  },
  horizontalLine: {
    margin: "0px auto",
    position: "relative",
    width: "96%",
    height: "2px",
    background: "rgba(98, 38, 207, 0.2)",
  },
  trendingBox: {
    position: "relative",
    bottom: "1.5px",
  },

  label: {
    fontSize: "11px",
    lineHeight: "20px",
    color: "black",
    marginBottom: 5,
    fontWeight: 400,
  },

  value: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "black",
    fontWeight: 600,
    marginRight: 16,
  },

  trend: {
    fontSize: "14px",

    fontWeight: 400,
    letterSpacing: "-0.5px",

    color: (props) => (props.trend > 0 ? "#79E77D" : "#FF3636"),
    marginRight: 3,
  },
}));
