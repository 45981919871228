export const APP_NAME = "QUIDD";
export const APP_DASHBOARD_TITLE = `${APP_NAME} Dashboard`;
export const APP_STAKE_PAGE_TITLE = `${APP_NAME} Super Staking`;

export const APP_SUPERPOOL_PAGE_TITLE = `${APP_NAME} Super Pool`;

export const APP_FONT_NAME = "Poppins";

export const APP_TOTAL_STAKED_TEXT = "Total Staked";

export const APP_YOUR_STAKED_TEXT = "Quidd Staked";
export const APP_FEES_COLLECTED_TEXT = "Fees Collected";
export const APP_REWARDS_TEXT = "Rewards";
export const APP_POTENTIAL_REWARDS_TEXT = "Potential";

export const APP_CARD_BG_COLOR =
  "linear-gradient(142.4deg, #F2EFFE 1.19%, #8f4ed9 114.83%), linear-gradient(142.4deg, #64256a -8.44%, #5f3972 57.82%, #6B10D6 114.83%);";

export const APP_CARD_BORDER = "2px solid rgba(98, 38, 207, 0.2)";

export const APP_BTN_BACKGROUND =
  "linear-gradient(142.4deg, #F2EFFE 1.19%, #8f4ed9 114.83%), linear-gradient(107.63deg, #64256a -2.79%, #5f3972 103.77%)";

export const APP_BTN_BORDER = "2px solid rgba(98, 38, 207, 0.2)";

export const APP_BTN_BOX_SHADOW =
  "inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12)";

export const APP_BTN_BORDER_RADIUS = "10px";

export const APP_STAKING_MESSAGE_POLYGON =
  "Your staked tokens will be locked for 30 days.";

export const APP_STAKING_MESSAGE_ETH =
  "Your staked tokens will be locked for 30 days.";

export const APP_UNSTAKING_MESSAGE =
  "A 25% toll is deducted from your QUIDD rewards when exiting a pool or claiming rewards. These tokens are then distributed amongst Super Stakers, boosting APY.";

export const APP_TOKEN_NAME = "QUIDD";

export const TOP_FIVE_USERS_QUERY = `
{
  stakes(first:5,orderBy:amount, orderDirection:desc){
    id
    amount
  }
}
`;

export const TX_HISTORY_QUERY = `{
  stakehistories(first:1000,orderBy:time, orderDirection:desc){
    id
    staker
    amount
    tx_hash
    type
    time
  }
}`;

export const COIN_GECKO_BASE_URL = "https://api.coingecko.com/api/v3";

export const COIN_GECKO_COIN_ID = "quidd";

export const ETHERSCAN_API_KEY = "CH4P1UU8CNBDZD25WX9EXQY9XDGBZMG2VD";

export const ETHERSCAN_BASE_URL = "https://api.etherscan.io";

export const COIN_CIRC_SUPPLY = 10127996;

export const TWITTER_HANDLE = "@QUIDDToken";

export const AMOUNT_FEE_RATIO = 0.75;

export const APP_TWITTER_URL = "https://twitter.com/quiddtoken";

export const APP_MEDIUM_URL = "https://medium.com/@quidd";

export const APP_DISCORD_URL = "https://discord.com/invite/xZQx8EKy8R";

export const APP_TELEGRAM_URL = "https://t.me/quiddtoken";

export const APP_LINKEDIN_URL = "https://www.linkedin.com/company/quidd-inc/";

export const COUNT_PER_PAGE = 10;
