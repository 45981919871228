import React from "react";
import { Link } from "react-router-dom";
import { QuiddLogo } from "../assets";
const PageNotFound = () => {
  return (
    <div id="wrapper">
      {/* <img src={QuiddLogo} /> */}
      <div id="info">
        <h3>This page could not be found</h3>
        <Link to="/">Go to home page</Link>
      </div>
    </div>
  );
};

export default PageNotFound;
