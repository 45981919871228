import { Box, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { DocsIcon, MailIcon, TelegramIcon, TwitterIcon } from "../../assets";
import BlockBtn from "../stake_components/BlockBtn";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 18,
    fontWeight: 500,
    color: "black",
    marginBottom: 24,
    lineHeight: "10px",
  },

  text: {
    fontWeight: 400,
    marginBottom: 30,
    fontSize: 16,
    lineHeight: "20px",
    color: "black",
    textAlign: "center",
  },

  contentContainer: {
    width: 400,
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",
    border: "2px solid rgba(255, 255, 255, 0.05)",

    borderRadius: 15,
    padding: 20,
    display: "flex",
    minHeight: 200,

    flexDirection: "column",
    justifyContent: "center",
    margin: "0 20px",

    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

const MessageModal = ({ onSuccess, message }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={classes.contentContainer}>
        <Box
          display="flex"
          justifyContent="flex-end"
          onClick={onSuccess}
          marginBottom={"20px"}
        >
          <CloseIcon style={{ color: "black", cursor: "pointer" }} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          {/* <Typography className={classes.heading}>Help</Typography> */}

          <Typography className={classes.text}>{message}</Typography>

          <BlockBtn
            label={"Close"}
            handleClick={onSuccess}
            // disabled={!stakingPeriod || web3Context?.chainId === 56}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MessageModal;
