import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AppRoute from "./containers/AppRoute";
import { ThemeProvider } from "@material-ui/core/styles";

import "./App.css";
import Layout from "./components/Layout";
import Dashboard from "./containers/Dashboard";

import { Web3ReactProvider } from "@web3-react/core";

import { getLibrary } from "./utils/getLibrary";

import Snackbar from "./components/Snackbar";

import SuperPool from "./containers/SuperPool";

import Market from "./containers/Market";
import AppContainer from "./containers/AppContainer";
import PageNotFound from "./containers/pageNotFound";
import MarketX from "./containers/MarketX";

import { theme } from "./theme";

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ThemeProvider theme={theme}>
        <Router>
          <Snackbar />

          <Switch>
            {/* <Layout> */}
            <AppRoute exact path="/" component={Dashboard} />

            <AppRoute exact path="/stake" component={AppContainer} />
            <AppRoute exact path="/super-pool" component={SuperPool} />
            <AppRoute exact path="/swap" component={MarketX} />
            <AppRoute exact path="/market" component={Market} />
            {/* </Layout> */}
            <Route path="*" component={PageNotFound} />
            <Redirect to="/404" />
            {/* <Route component={PageNotFound} /> */}
          </Switch>
        </Router>
      </ThemeProvider>
    </Web3ReactProvider>
  );
}

export default App;
