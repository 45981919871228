import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { APP_BTN_BORDER } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 40,
    height: 30,
    marginRight: 5,
    [theme.breakpoints.between(960, 1097)]: {
      marginRight: "0px !important",
      padding: "6px 2px !important",
    },
    [theme.breakpoints.down(400)]: {
      marginRight: "0px !important",
      padding: "6px 2px !important",
    },
  },

  selectedContainer: {
    background: ({ selected }) =>
      selected ? theme.palette.themeColor1 : "#fff",
    border: ({ selected }) =>
      selected ? "2px solid transparent" : APP_BTN_BORDER,
    borderRadius: "10px",
    minWidth: 40,
    height: 30,
    marginRight: 5,
    [theme.breakpoints.between(960, 1097)]: {
      marginRight: "0px !important",
      padding: "6px 2px !important",
    },
    [theme.breakpoints.down(400)]: {
      marginRight: "0px !important",
    },

    "&:hover": {
      color: ({ selected }) => (selected ? "#fff" : "#121B2D"),
      background: ({ selected }) =>
        selected ? theme.palette.themeColor1 : "#fff",
    },
  },
  btnText: {
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    color: ({ selected }) => (selected ? "white" : "black"),

    opacity: ({ selected }) => (selected ? 1 : 0.6),
  },
}));

const TimeRangeButton = ({ text, selected, onClick }) => {
  const classes = useStyles({ selected });

  return (
    <Button
      onClick={onClick}
      className={selected ? classes.selectedContainer : classes.container}
    >
      <Typography className={classes.btnText}>{text}</Typography>
    </Button>
  );
};

export default TimeRangeButton;
