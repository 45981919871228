import { makeStyles, Box } from "@material-ui/core";
import React from "react";
import TokenStat from "./TokenStat";
import { useDispatch, useSelector } from "react-redux";
import { numberTest, sixDigitsFormatter, trunc } from "../../utils/formatters";
import { COIN_CIRC_SUPPLY } from "../../utils/constants";

const TokenStatsCard = () => {
  const classes = useStyles();

  const coinCurrentPrice = useSelector(
    (state) => state.web3.data.coinCurrentPrice
  );

  const coin24hVolume = useSelector((state) => state.web3.data.coin24hVolume);
  const coin24hPriceChange = useSelector(
    (state) => state.web3.data.coin24hPriceChange
  );

  const sevenDayHighLow = useSelector(
    (state) => state.web3.data.sevenDayHighLow
  );

  const demandFactor = useSelector((state) => state.web3.data.demandFactor);

  const demandFactorV2 = useSelector((state) => state.web3.data.demandFactorV2);

  console.log("sevenDayHighLow => ", sevenDayHighLow);

  return (
    <Box className={classes.container}>
      <TokenStat
        label="Price"
        value={coinCurrentPrice ? `$${coinCurrentPrice}` : "-"}
        trend={coin24hPriceChange?.toFixed(2)}
      />
      <TokenStat
        label="Market Cap"
        value={
          coinCurrentPrice
            ? `$${sixDigitsFormatter(COIN_CIRC_SUPPLY * coinCurrentPrice)}`
            : "-"
        }

        // trend="-6.9"
      />
      <TokenStat
        label="Circulating supply"
        value={`${sixDigitsFormatter(COIN_CIRC_SUPPLY)}`}

        // trend="-6.9"
      />
      <TokenStat
        label="Trading Volume"
        value={coin24hVolume ? `$${sixDigitsFormatter(coin24hVolume)}` : "-"}
        // trend="6.9"
      />
      <TokenStat
        label="7d Low / 7d High"
        value={
          sevenDayHighLow[0] && sevenDayHighLow[1]
            ? `$${sevenDayHighLow[1]?.toFixed(
                8
              )} / $${sevenDayHighLow[0]?.toFixed(8)}`
            : "-"
        }
        // trend="6.9"
      />
      {demandFactorV2 && (
        <TokenStat
          label="Demand Factor"
          value={`${demandFactorV2}`}
          //  trend="-6.9"
        />
      )}
    </Box>
  );
};

export default TokenStatsCard;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    // minWidth: 310,
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: "25px",
    // padding: 24,
    paddingTop: 0,
    paddingBottom: 0,
    // marginRight: 30,

    [theme.breakpoints.down("sm")]: {
      padding: 16,

      marginBottom: 16,
    },
  },
}));
