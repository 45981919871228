import { Box, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { DocsIcon, MailIcon, TelegramIcon, TwitterIcon } from "../../assets";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 18,
    fontWeight: 500,
    color: "black",
    marginBottom: 24,
    lineHeight: "10px",
  },

  text: {
    fontWeight: 400,
    marginBottom: 20,
    fontSize: 16,
    lineHeight: "20px",
    color: "black",
  },

  contentContainer: {
    width: 400,
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",
    border: "2px solid rgba(255, 255, 255, 0.05)",

    borderRadius: 15,
    padding: 30,
    display: "flex",

    flexDirection: "column",
    margin: "0 20px",

    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

const HelpModal = ({ onSuccess }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={classes.contentContainer}>
        <Box display="flex" justifyContent="flex-end" onClick={onSuccess}>
          <CloseIcon style={{ color: "black", cursor: "pointer" }} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={classes.heading}>Help</Typography>

          <Typography className={classes.text}>
            Super Staking rewards users through protocol growth, enabling you to
            become part of the networks you love. For any questions, use the
            links below.
          </Typography>
          <Grid container direction="row" justify="center" alignItems="center">
            <Link href="https://telegram.me/dafiprotocol" target="_blank">
              <img src={TelegramIcon} style={{ margin: "17px" }} />
            </Link>
            <Link href="http://docs.dafiprotocol.io/" target="_blank">
              <img src={DocsIcon} style={{ margin: "17px" }} />
            </Link>
            <Link href="http://twitter.com/dafiprotocol" target="_blank">
              <img src={TwitterIcon} style={{ margin: "17px" }} />
            </Link>
            <Link href="mailto:support@dafiprotocol.io" target="_blank">
              <img src={MailIcon} style={{ margin: "17px" }} />
            </Link>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpModal;
