import { createTheme } from "@material-ui/core";
import { APP_CARD_BG_COLOR, APP_FONT_NAME } from "./utils/constants";

export const theme = createTheme({
  typography: {
    fontFamily: APP_FONT_NAME,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    color: "red",
  },

  palette: {
    background: "white",
    // primary: "white",
    cardBgColor: "#F2EFFE",
    snackbarColor:
      "linear-gradient(182.75deg,rgba(96, 83, 132, 0.7) -94.02%,rgba(15, 8, 36, 7) 153.25%)",

    themeColor1: "#6B10D6",
    themeColor1RGBA: "107, 16, 214",
    themeGradient1:
      "linear-gradient(0deg, #6B10D6, #6B10D6), linear-gradient(0deg, #F2EFFE, #F2EFFE), linear-gradient(107.63deg, #302B63 -2.79%, #302B63 103.77%);",
    themeBoxShadow1:
      "inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12)",
    border: "2px solid rgba(98, 38, 207, 0.2)",

    textGradient1:
      "linear-gradient(29.15deg, #1E1747 1.81%, #A16DFF 60.32%, #6226CF 113.03%), linear-gradient(94.63deg, #65D7F5 -3.37%, #7D3CF0 89.21%), linear-gradient(94.63deg, #C881FF -3.37%, #FF4E80 89.21%), linear-gradient(104.25deg, #A35CED 0%, #FF377F 100%), linear-gradient(94.63deg, #8797FF -3.37%, #B55DFF 89.21%);",
    textColor: "black",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
});
